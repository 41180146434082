.about-card1 h3{
    color: #044188;
    font-weight: 700;
}
.about-btn{
    border: 1px solid #044188;
    background-color: white;
width: 150px; 
border-radius: 5px;
height: 40px;
   font-size: 12px;
}
.about-card2 img{
width: 100%;
}
.values{
    background-color: #DDE1FC;
    margin-bottom: 80px;
    padding: 20px;
}
.about-cal{
    width: 300px;
    height: 55px ;
    border-radius: 5px;
    background-color: #ffff;
    border: none;
}
.about-bg{
background-image: url(../../images/bg.jpeg);
background-repeat: no-repeat;
background-size: cover;
height: 150px;
align-items: center;
margin-bottom: 50px;
}
@media only screen and (max-width: 600px) {
    .about-bg h3{
font-size: 16px;
    }
 
  .about-bg{
    height: 100px;
    padding: 20px;
    background-size: cover;
  }
  .about-cal{
    width: 200px;
    height: 50px ;
   font-size: 13px;
}
}
@media only screen and (min-width: 2600px) {
    .about-bg h3{
        font-size: 30px;
    }
}
@media only screen and (max-width: 575px) {
    .about-row2,.values{
flex-direction: column;
    }
.about-card2{
   display: none;
}
.about-card2 img{
  
}
.footer1-card{
    display: none !important;
}
}