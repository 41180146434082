.nav-link {
  transition: none;
  margin-left: 50px !important;
}
.nav-body{
  padding: 10px;
  background-color: #f4f9fa;
}
.nav-link:hover {
  background-color: #044188;
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
}
.footer1-card h4{
   font-size: 16px;
}
.nav-btn {
  box-shadow: 0px 9px 46px 8px #00000040;
  background-color: #ffffff;
  color: #075cc7;
  border: none;
  margin-left: 50px;
  border-radius: 5px;
  width: 150px;
  height: 40px;
}
@media only screen and (max-width: 768px) {
  .nav-link{
    margin-left: 0px !important;
    transition: none !important;
    box-shadow: none;
  }
  .nav-btn {
    margin-left: 0px;
    margin-bottom: 10px;
    box-shadow: none;
  }
  .nav-logo {
    width: 40%;
  }
.nav-body{
  padding: 0px;
  font-size: 12px;
}
  .navbar-toggler {
    padding: 0px !important;
    text-decoration: none;
    border: none !important;
  }
  .nav-link:hover {
    width: 30%;
    transition: none !important;
    padding: 5px;
  }
  .navbar-toggler-icon {
    width: 20px !important;
  }
 
}
@media only screen and (max-width: 990px) {
  .nav-link{
    margin-left: 0px !important;
  }
  .nav-btn {
    margin-left: 0px;
    margin-bottom: 10px;
    box-shadow: none;
  }
}
