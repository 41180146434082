.footer1-card {
  background-color: #044188;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #ffff;
}

.footer-links-holder {
  display: flex;
}
.footer-links {
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #ffffff;
}
.footer1 {
  background-color: #044188;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  color: #ffffff;
  /* margin: 0 50px; */
}
.footer h4 {
  font-weight: 400;
}
.social-icon img {
  width: 25px;
}
.contact-form {
  justify-content: space-evenly;
  display: flex;
}
.contact-form input {
  border-radius: 5px;
  border: none;
  outline: none;
  height: 33px;
}
.contact-form input:hover {
  outline: none;
}
textarea {
  outline: none;
  border-radius: 5px;
}
textarea:hover {
  outline: none;
}
.form-btn {
  background-color: #ffffff;
  color: #044188;
  border: none;
  margin-top: 10px;
  height: 33px;
  border-radius: 2px;
}
.contact-form label {
  padding-bottom: 5px;
}
.social-icon img {
  margin-right: 5px;
  margin-left: 5px;
  width: 17px;
  height: 15px;
}
.insta-icon {
  width: 15px !important;
  height: 15px !important;
}
ul.no-bullets {
  list-style-type: none;
  padding: 0;
}
.foot-img {
  width: 150px !important;
}
.foot-img img {
  width: 100%;
}
ul.no-bullets li {
  font-size: 14px;
  line-height: 25px;
}

input.form-control {
  background-color: #d8e3f0;
  height: 50px;
  outline: none;
}
.sub-form {
  width: 80%;
  margin-top: 50px;
}
.input-group .btn {
  background-color: #83460f;
  color: #ffff;
  border: none;
}
.input-group .btn:hover {
  background-color: #83460f;
}
.form-control {
  border: none;
}
@media only screen and (max-width: 990px) {
  .contact-form {
    flex-direction: column;
  }
  .sub-form {
    width: 100%;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-row {
    flex-direction: column-reverse;
    display: flex;
  }
}
@media only screen and (max-width: 575px) {
  .footer1-card {
    display: none !important;
  }
  .subscribe-btn {
    margin-top: 10px;
  }
  .footer1 {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 769px) {
  .sub-form {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
