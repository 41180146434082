
.service{
  width: 100%;
}
.service-container {
  height: 100%;
  background-color: #044188;
}
/* .card-text {
  color: hsl(0, 0%, 100%);
width: 50%;
padding-left: 100px;
}
.card-image {
  padding-right: 250px;

} */
.card-image img {
height: 400px;
}
.service-btn {
  width: 50%;
  height: 60px;
  box-shadow: 0px 9px 46px 8px #00000040;
  color: #044188;
  border-radius: 5px;
  border: none;
}
.product-card {
  height: 100%;
  color: #ffff;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: #044188;
}
.product-btn {
  background-color: #044188;
  border: 1px solid #ffff;
  font-size: 12px;
  border-radius: 5px;
  color: #ffff;
  /* height: 40px; */
}
.product-card-img img {
  width: 150px !important;
}
.service-card-info{
  padding-left: 50px !important;
}

@media only screen and (max-width: 769px){
  .service-container{
    display: flex;
  }
  .serice-card-img img{
    display: none ;
  }
  .serice-card-img {
    display:hidden; }

    .service-card-info{
      text-align: center;
      padding: 30px;
    }
    .service-card-info{
      padding-left: 0px !important;
    }
    
 }


 @media only screen and (min-width:1300px) {
  .product-card{
      height: 500px;
      font-size: 18px;
    }
    .product-card-img img {
      width: 200px;
    }
   
 }