body{
    overflow: none;
}
.service-page-container {
    height: 100%;
    display: flex;
    background-color:#EEF0FE;
  }
  .service-page-text {
    color: #044188;
    width: 50%;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
  }
  .loan-btn{
    background-color: #044188;
    border: none;
    border-radius: 5px;
    width: 200px;
    color: #ffff;
    height: 50px;
  }
  .service-page-image {
width: 50%;   
text-align: center;
  }
  .service-page-image img{
width: 100%;
  }
  .service-page-btn2 {
    width: 200px;
    height: 60px;
    background-color:#EEF0FE;
    color: #044188;
    border-radius: 5px;
    border: none;
    border: 1px solid  #044188 ;
  }
  .service-page-btn {
    width: 200px;
    height: 60px;
    background-color:#EEF0FE;
    color: #044188;
    border-radius: 5px;
    border: none;
    border: 1px solid  #044188 ;
  }
  .service-page-btn1{
    width: 200px;
    height: 60px;
    background-color:#044188;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 20px;
    border: none;
  }
  /* .service-products{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  } */
  .service-products2{
    background-color:#EEF0FE; 
  }
.loan-image img {
  width: 100%;
}
.loan-info{
  display: flex;
    flex-direction: column;
    justify-content: center;
}
.product-img{
  text-align: center;
}
.product-img img{
  width: 200px;
}
.sectors{
  height:100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffff;
}
@media only screen and (min-width: 500px) {
  .btn-box{
    display: flex;
  }
}
@media only screen and (max-width: 500px) {
  .service-page-btn2{
    margin-top: 10px;
  }
 .Service h1,h2,h3,h4{
  font-size: 14px !important;
  font-weight: 800;
}
.service-page-text p{
  font-size: 10px !important;
}
.service-page-container{
  padding: 20px;
}
.service-page-text{
  padding-left: 0px;
}
.service-page-btn{
  width: 150px;
  font-size: 10px;
  height: 30px;
}
.service-product{
  font-size: 14px;
  padding: 10px;
}
.sector-info{
  font-size:12px
}
.sector-img{
width: 300px ;
margin: 5px;
}
.sector-img img {
  width: 100%;
}
}

@media only screen and (max-width: 740px) {
.service-page-text h1{
   font-size: 20px;
   
}

.loan-image{
  display: none;
}
}