.faq-card{
    background-color:#044188;
    color:#ffff;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faq-card h1{
font-size: 50px;
font-weight: 800;
}

button.accordion-button{
    background-color: #044188 !important;
    color: white;
    text-decoration: none;
    outline: none;
    border: none;
  }
  .accordion-button::after {
    background-image: url(../../images/arrow-204-48.png);
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url(../../images/arrow-204-48.png);
  }

.accordion-button:not(.collapsed){
    color: #fff;
    outline: none;
    border: none;
}

  @media only screen and (max-width: 768px) {
    .faq-position{
    flex-direction: column;
  }
  .faq-card h1{
    font-size: 30px;
  }
  }