
.contact-text{
    background-color: #DDE1FC;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.form-card form{
display: flex;
}
.form-card{
    justify-content: center;
    background-color: #044188   ;
    margin-bottom: 50px;
}
.contact-img{
    width: 100%;
    height: 100%;
}
.contact-btn{
    background-color: inherit;
    color: #044188;
    border: 1px solid #044188;
    height: 40px;
    width: 200px;
border-radius: 5px;
}
@media (max-width: 768px) {


}