
.about-header {
  background-color: #044188;
  font-family: Poppins;
  color: white;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}
.about-row {
  background-color: #DDE1FC;
  padding: 20px;}
.about-image img{
width: 100%;
height: 60vh;
}
.team-image{
  /* width: 100%; */
  height: 300px;
  display: flex;
  justify-content: center;
}
.team-image img{
  border-radius: 5px;
}
.team-profile{
   width: 100%;
}
.card-body h1 {
  color: #044188;
  font-size: 18px;
}
.card-body{
  text-align: center;
  padding-top: 10px;
}
@media only screen and (max-width: 780px) {
   .card{
width: 300px;
   }
   .about-image{
    display: none !important;
   }
   .about-header{
    width: 100%;
    font-size: 30px;
    height: 300px;
   }
   .team-image img {
    width: 200px;;

   }
   .team-image{
    width: 100%;
   }
   .about-image{
    display: none;
  }
}
@media only screen and (max-width: 1705px) {
  .about-image img{
   
    }
}
@media only screen and (max-width: 575px) {
  .about-header{
    font-size: 30px;
    }
    .about-image{
      display: none;
    }
}