.page-container{
    height: 100vh;
}

.main{
    background-color: #044188;
    width: 100%;
    text-align: center;
    height: 500px;
    display: flex;
    color: #ffff;
    line-height: 30px;
    flex-direction: column;
}
.main h1,.main h2{
    font-size: 100px;
    font-weight: 700;
}

.main p{
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .main{
        margin: 10px;
        padding: 10px;
    }
    .main h2{
        font-size: 40px!important;
    }
    .main p{
        font-size: 16px;
    }
    
}