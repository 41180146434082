.calculator-card {
  background-color: #044188;
  color: #ffff;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calculator-card h1 {
  font-size: 50px;
  font-weight: 800;
}
.calculator-body2 {
  height: 100%;
  padding-bottom: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 70%;
}
.calculator-body {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.calculator-text h1 {
  font-size: 30px;
  font-weight: 800;
}
.amount-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.amount {
  background-color: #044188;
  color: #ffff;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 90%;
}
.amount h3 {
  font-size: 40px;
  font-weight: 800;
}
.form-floating input {
  width: 100%;
  
}




.cal-form {
  width: 100%;
  text-align: center;
}
/* .form-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
.cal-btn1 {
  border: none;
  height: 50px;
  width: 100%;
  color: #ffff;
  background-color: #044188;
  border-radius: 2px;
}
.cal-btn2 {
    width: 100%;
  height: 50px;
  color: #044188;
  background-color: #ffff;
  border-radius: 2px;
  border: 1px solid #044188;
}

@media only screen and (max-width: 600px) {
  .calculator-card h1 {
    font-size: 30px;
  }
  .calculator-text h1 {
    font-size: 20px;
  }
  .calculator-text p{
    font-size: 12px;
  }
  .calculator-body2{
    width: 100%;
  }
}
